import React from 'react';
import styled from 'styled-components';
import {Link } from "react-router-dom";

const OpeningsSection = styled.div`
    background: linear-gradient(rgba(255,255,255,0) 55px, #E4E1F1 55px 100%);
    float: left;
    position: relative;
    width: 100%;
`;
const OpeningsWrapper = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: 1220px;
    overflow: hidden;
    padding: 0 10px 3rem;
    position: relative;
    width: 100%;
`;

const OpeningsImage = styled.img`
    max-width: 362px;
    // max-height: 280px;
    object-fit: cover;

    @media screen and (max-width: 768px) {
        max-width: 180px;
        object-position: 80% 50%;
    }
    @media screen and (max-width: 550px) {
        max-width: 120px;
    }
`;

const OpeningsContent = styled.div`
    background: #1D1D1D;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    padding: 70px;

    @media screen and (max-width: 768px) {
        padding: 35px;
    }
    @media screen and (max-width: 550px) {
        padding: 25px;
    }
`;
const OpeningsContentTitle = styled.div`
    color: #00FFC2;
    font-size: 60px;
    font-weight: 100;
    line-height: 1;

    @media screen and (max-width: 768px) {
        font-size: 45px;
    }
    @media screen and (max-width: 550px) {
        font-size: 34px;
    }
`;
const OpeningsContentBody = styled.div`
    color: #ffffff;
    font-size: 18px;
    padding: 1rem 0;

    @media screen and (max-width: 768px) {
        font-size: 17px;
        padding: 10px 0;
    }
`;
const OpeningsContentLink = styled(Link)`
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

const Openings = ({ data }) => {
    return (
        <>
            <OpeningsSection>
                <OpeningsWrapper>
                    <OpeningsImage src={data.asset} />
                    <OpeningsContent>
                        <div>
                            <OpeningsContentTitle>{data.title}</OpeningsContentTitle>
                            <OpeningsContentBody>{data.body}</OpeningsContentBody>
                        </div>
                        <OpeningsContentLink to="#">{data.link}</OpeningsContentLink>
                    </OpeningsContent>
                </OpeningsWrapper>
            </OpeningsSection>
        </>
    )
}

export default Openings;