import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { Data } from '../../data/ServicesData';

import ServicesHero from '../layouts/services/ServicesHero';
import Experties from '../layouts/home/Experties';
import NeedExperts from '../layouts/services/NeedExperts';
import Openings from '../layouts/Openings';
import Footer from '../layouts/Footer';

import ScrollToTopOnMount from '../ScrollToTop';

const SectionsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled.section`
  // float: left;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 10px;
  position: relative;
  width: 100%
`;

const Services = ({setPageID}) => {
  useEffect(() => {
    setPageID(1);

    // return () => {
    //     setPageID(1);
    // };
  }, []);
  return (
    <>
      <ScrollToTopOnMount />
      <ServicesHero data={Data.home} />
      <SectionsContainer>
        <SectionWrapper>
          <Experties data={Data.experties} />
        </SectionWrapper>
        <SectionWrapper>
          <NeedExperts data={Data.needexperts} />
        </SectionWrapper>
        <SectionWrapper style={{maxWidth: "100%", padding: "0", borderBottom: "2px solid #FFFFFF"}}>
          <Openings data={Data.openings} />
        </SectionWrapper>
        <SectionWrapper style={{maxWidth: "100%", padding: "0"}}>
          <Footer pageID={1} />
        </SectionWrapper>
      </SectionsContainer>
    </>
  )
}

export default Services