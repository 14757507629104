import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { Data } from '../../data/HomeData';

import HomeHero from '../layouts/home/HomeHero';
import Industries from '../layouts/home/Industries';
import Experties from '../layouts/home/Experties';
import TalkWithUs from '../layouts/home/TalkWithUs';
import Accreditations from '../layouts/home/Accreditations';
import Openings from '../layouts/Openings';
import Footer from '../layouts/Footer';

import ScrollToTopOnMount from '../ScrollToTop';

const SectionsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled.section`
  // float: left;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 10px;
  position: relative;
  width: 100%;
`;

const Home = ({setPageID}) => {
  useEffect(() => {
    setPageID(0);

    // return () => {
    //     setPageID(0);
    // };
  }, []);
  return (
    <>
      <ScrollToTopOnMount />
      <HomeHero data={Data.home} />
      <SectionsContainer>
        <SectionWrapper>
          <Experties data={Data.experties} />
          <Industries data={Data.industries}/>
        </SectionWrapper>
        <SectionWrapper style={{ padding: "0" }} >
          <TalkWithUs data={Data.talkwithus} />
        </SectionWrapper>
        <SectionWrapper style={{maxWidth: "100%", borderTop: "1px solid rgba(160,125,195,0.3"}}>
          <Accreditations data={Data.accreditations}></Accreditations>
        </SectionWrapper>
        <SectionWrapper style={{maxWidth: "100%", padding: "0", borderBottom: "2px solid #FFFFFF"}}>
          <Openings data={Data.openings} />
        </SectionWrapper>
        <SectionWrapper style={{maxWidth: "100%", padding: "0"}}>
          <Footer pageID={0} />
        </SectionWrapper>
      </SectionsContainer>
    </>
  )
}

export default Home;