import BannerImage from "../images/aboutus-banner.png";
import PriorityGraphic from "../images/our-priority-avatar.png"
import OpeningsImage from "../images/openings.png";
import OurValueIcon1 from "../images/our-value-icon1.svg";
import OurValueIcon2 from "../images/our-value-icon2.svg";
import OurValueIcon3 from "../images/our-value-icon3.svg";
import OurValueIcon4 from "../images/our-value-icon4.svg";
import Leader1 from "../images/leader1.png";
import Leader2 from "../images/leader2.png";
import SocialPlatform1 from "../images/linkedin-logo.svg";
import Customer1 from "../images/chanel-logo.png"
import Customer2 from "../images/fujitsu-logo.png"
import Customer3 from "../images/decoded-logo.png"
import Customer4 from "../images/bayer-logo.png"
import Customer5 from "../images/british-council-logo.png"
import Customer6 from "../images/mastercard-logo.png"
import Customer7 from "../images/santander-logo.png"
import Customer8 from "../images/unilever-logo.png"
import Customer9 from "../images/aviva-logo.png"
import Customer10 from "../images/tesco-logo.png"
import Customer11 from "../images/uk-gov-logo.png"

export const Data = {
    'home': {
        text: "Arize Digital",
        body: "We are a team of dedicated strategists, designers and engineers driven by curiosity and focused on impact.",
        path: "",
        asset: BannerImage
    },
    'ourStory': {
        title: "Our Story",
        body: "In 2020, we wanted to start something more than just a business; Our story is what happens when people are truly committed to showing up powerfully in life.<br><br>Our experienced team is full of passionate individuals who are dedicated to the contribution they can make in the world. A multi-disciplinary team having a common motivation—to deliver high quality solutions that affect, engage and change."
    },
    'ourPriority': {
        asset: PriorityGraphic,
        body: "Our priority is to cultivate a dedicated team that places customer satisfaction at the forefront while fostering a culture of innovation.<br><br>This approach enables us to view projects through diverse lenses, ensuring we deliver the most effective solutions. By doing so, we create products and services that inspire, guiding our customers as they embrace their digital future."
    },
    'ourValues': {
        title: "Our Values",
        items: [
            {
                body: "<span class='bold'>Trust</span><br>Be authentic, realistic, and practice principles of high character and competence.",
                asset: OurValueIcon1
            },
            {
                body: "<span class='bold'>Transparency</span><br>Consistently committed to being open and honest.",
                asset: OurValueIcon2
            },
            {
                body: "<span class='bold'>Integrity</span><br>Say what you do and do what you say. Be honest in motive, method, progress, and principles.",
                asset: OurValueIcon3
            },
            {
                body: "<span class='bold'>Leadership</span><br>Inspire others to dream more, learn more, do more, and become more.",
                asset: OurValueIcon4
            }
        ]
    },
    'leadership': {
        title: "Leadership",
        items: [
            {
                asset: Leader1,
                name: "Jitendra Ramchandani",
                title: "Co-founder",
                socialPlatform: SocialPlatform1,
                socialUrl: "http://linkedin.com/in/jitendramr"
            },
            {
                asset: Leader2,
                name: "Jay Bhardwaj",
                title: "Co-founder",
                socialPlatform: SocialPlatform1,
                socialUrl: "http://linkedin.com/in/jaybhardwaj1979"
            }
        ]
    },
    'testimonials': {
        title: "What our clients say about us",
        items: [
            {
                body: "\"Arize Digital consistently delivers innovative, user-focused solutions with unmatched precision. Their creativity and dedication make them an exceptional partner for any project.\""
            },
            {
                body: "\"Working with Arize Digital has been a game-changer for our business. Their team’s innovative approach and commitment to excellence helped us transform complex ideas into seamless digital solutions. They consistently go above and beyond to deliver exceptional results. Highly recommended!.\""
            },
            {
                body: "\"Arize Digital's expertise and professionalism made a significant impact on our project. Their ability to understand our needs and craft user-friendly solutions was remarkable. The final platform exceeded our expectations and has been instrumental in improving our processes.\""
            },
            {
                body: "\"Arize Digital brought our vision of a digital learning platform to life with creativity and precision. Their team’s dedication to understanding the nuances of our audience was key to creating an engaging and effective solution. They’ve set a new standard for excellence.\""
            }
        ]
    },
    'ourCustomers': {
        title: "Companies using our products and services",
        body: "Some of the companies who are using our products and services, and changing the world.",
        items: [
            {
                asset: Customer1
            },
            {
                asset: Customer2
            },
            {
                asset: Customer3
            },
            {
                asset: Customer4
            },
            {
                asset: Customer5
            },
            {
                asset: Customer6
            },
            {
                asset: Customer7
            },
            {
                asset: Customer8
            },
            {
                asset: Customer9
            },
            {
                asset: Customer10
            },
            {
                asset: Customer11
            }
        ]
    },
    'aboutUs': {
        block1: {
            title: "Case Studies",
            body: "See some of our selected work.",
            button: {
                label: "Case Studies",
                theme: "black"
            }
        },
        block2: {
            title: "Services",
            body: "Know about all services we provide.",
            button: {
                label: "Services",
                theme: "black"
            }
        }
    },
    'openings': {
        title: "Got talent?",
        body: "We are always looking for like-minded and talented people to join our team and grow together.",
        link: "See open positions",
        asset: OpeningsImage
    }
};