// export const menuData = [
//     { title: "Home", link:"/home" },
//     { title: "Services", link:"/services" },
//     { title: "Approach", link:"/approach" },
//     { title: "About", link:"/about-us" },
//     { title: "Careers", link:"/careers" },
//     { title: "Contact", link:"/contact-us" }
// ]
export const menuData = [
    { title: "Home", link: "/home" },
    { title: "Services", link: "/services" },
    { title: "Approach", link: "/approach" },
    { title: "About", link: "/about-us" },
    { title: "Careers", link: "#" },
    { title: "Contact", link: "/contact-us" }
]