import React, { useState } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import GlobalStyle from './globalStyles';
import Navbar from './components/layouts/Navbar';
import Dropdown from './components/Dropdown';

import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Approach from './components/pages/Approach';
// import DigitalLearning from './components/pages/DigitalLearning';
// import DesignExperience from './components/pages/DesignExperience';
// import ProductDevelopment from './components/pages/ProductDevelopment';
import ContactUs from './components/pages/ContactUs';
import AboutUs from './components/pages/AboutUs';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [pageID, changeID] = useState(0);

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const setPageID = (id) => {
    changeID(id);
  }

  return (
    <>
      <Router>
        <GlobalStyle />
        <Navbar toggle={toggle} pageID={pageID} />
        <Dropdown isOpen={isOpen} toggle={toggle} pageID={pageID} />
        <Routes>
          <Route exact path="/home" element={<Home setPageID={setPageID} />} />
          <Route exact path="/services" element={<Services setPageID={setPageID} />} />
          <Route exact path="/approach" element={<Approach setPageID={setPageID} />} />
          {/* <Route exact path="/digital-learning" element={<DigitalLearning setPageID={setPageID} />}/>
        <Route exact path="/design-experience" element={<DesignExperience setPageID={setPageID} />}/>
        <Route exact path="/product-development" element={<ProductDevelopment setPageID={setPageID} />}/> */}
          <Route exact path="/about-us" element={<AboutUs setPageID={setPageID} />} />
          <Route exact path="/contact-us" element={<ContactUs setPageID={setPageID} />} />
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
