import React from 'react';
import styled from 'styled-components/macro';
import Styles from '../../css/styles.module.css';
import Slider from '../Slider';

const IndustriesSection = styled.section`
    background-color:  #E3E1F1;
    color: #ffffff;
    float: left;
    position: relative;
    width: 100%;
`;

const IndustriesWrapper = styled.div`
    overflow: hidden;
    padding: 0 0 80px;
    position: relative;
    width: 100%;
`;

const BlockOne = styled.div`
    margin: 0 auto 80px auto;
    max-width: 880px;
    padding: 0 10px;

    .title {
        margin: 70px 0 30px;
    }
`;
const BlockOneTitle = styled.div`
    // color: #FFFFFF !important;
    text-align: center;
`;
const BlockOneBody = styled.div`
    text-align: center;
`;
const BlockTwo = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1070px;
    padding: 0 10px;
`;

const Industries = ({ data }) => {
  return (
    <>
        <IndustriesSection >
            <IndustriesWrapper >
                <BlockOne>
                    <BlockOneTitle className={'title '+Styles.standardTitle}>{data.title}</BlockOneTitle>
                    <BlockOneBody className={Styles.standardBody}>{data.body}</BlockOneBody>
                </BlockOne>
                <BlockTwo className='blockTwo'>
                    <Slider slides={data.items}/>
                </BlockTwo>
            </IndustriesWrapper>
        </IndustriesSection>
    </>
  )
}

export default Industries;