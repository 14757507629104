import React from 'react';
import styled from 'styled-components/macro';
import Marquee from 'react-fast-marquee';
import Styles from '../../css/styles.module.css';

const ComponentWrapper = styled.div`
    margin: 3.5rem auto 140px;
    max-width: 1200px;
    
    @media screen and (max-width: 768px) {
        margin: 3rem auto;
    }
`
const MarqueeImage = styled.img`

`;
const AccreditationsTitle = styled.div`
    margin-bottom: 0;
    padding: 0 0 3.5rem 0;
    text-align: center;

    @media screen and (max-width: 768px) {
        padding: 0 0 3rem 0;
    }
`;

const Accreditations = ({ data }) => {
    
    return (
        <ComponentWrapper>
            <AccreditationsTitle className={'title '+Styles.standardTitle}>{data.title}</AccreditationsTitle>
            <Marquee autoFill gradient gradientWidth={150}>
                {data.items.map((item, index) => {
                    return (
                        <MarqueeImage key={index} src={item.asset} />
                    )
                })}
            </Marquee>
        </ComponentWrapper>
    )
}

export default Accreditations;