import React from 'react';
import IntersectionVisible from 'react-intersection-visible';
import styled, { keyframes } from 'styled-components/macro';
import Styles from '../../css/styles.module.css';

const SlideIn = keyframes`
    0%   {top: 100vh}
    100% {top: 0}
`
const BreathInOut = keyframes`
    0%, 100% {top: 0}
    50% {top: -30px}
`

const ComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto 6.8rem;
    max-width: 1200px;
    row-gap: 1.1rem;
    
    & > * {
        flex: 0 100%;
    }

    .slideIn {
        margin: 0 auto;

        img {
            max-width: 256px;
            position: relative;
            top: 100vh;
        }
        &.animate-in img {
            animation: 1s linear 1  ${SlideIn}, 2.5s ease-in-out 1s infinite ${BreathInOut};
        }
    }
`

const EmailUsTitle = styled.div`
    margin-bottom: 0;
    // padding: 0 0 1.1rem 0;
    text-align: center;

    @media screen and (max-width: 768px) {
        // padding: 0 0 3rem 0;
    }
`
const EmailUsBody = styled.div`
    font-size: 36px;
    font-weight: 100;
    text-align: center;

    @media screen and (max-width: 520px) {
        font-size: 30px;
    }
`
const SpecialistImg = styled.img`
    padding-top: 140px;
`
const EmailUs = ( { data }) => {
    const onShow = ( entries ) => {
        if(!entries[0].target.classList.contains('animate-in')) {
            entries[0].target.classList.add('animate-in');
        }
    }
    return (
        <>
            <ComponentWrapper>
                <EmailUsTitle className={'title '+Styles.standardTitle}>{data.title}</EmailUsTitle>
                <EmailUsBody>{data.body}</EmailUsBody>
                <IntersectionVisible options={{threshold: 1}} className='slideIn' onShow={ e => onShow( e ) }>
                    <SpecialistImg src={data.asset} />
                </IntersectionVisible>
            </ComponentWrapper>
        </>
    )
}

export default EmailUs;