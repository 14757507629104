import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const NeedExpertsSection = styled.div`
    padding: 10rem 0 3rem;
`;
const NeedExpertsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const SectionOne = styled.div`
    align-items: center;
    column-gap: 1rem;
    display: flex;
    justify-content: center;
    white-space: pre-line;

    & > * {
        flex: 0 auto;
    }
`;
const SectionTwo = styled.div`
    column-gap: 50px;
    display: flex;
    padding-top: 10rem;

    @media screen and (max-width: 825px) {
        flex-direction: column;
        row-gap: 50px;
    }
`;
const BackgroundImage = styled.img`
    @media screen and (max-width: 520px) {
        max-width: 130px;
        width: 100%;
    }
`;
const NeedExpertsBody = styled.div`
    font-size: 24px;
    font-weight: 100;

    @media screen and (max-width: 768px) {
        font-size: 19px;
    }
`;
const NeedExpertsLink = styled(Link)`
    color: #000000;
    font-family: "sharp-grotesk-book", sans sarif;
    font-size: 24px;

    @media screen and (max-width: 768px) {
        font-size: 19px;
    }
`;
const ActionLinkBtn = styled(Link)`
    background: #6966FF;
    color: #FFFFFF;
    padding: 78px 0;
    font-size: 45px;
    font-weight: 100;
    font-style: italic;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
`;

const NeedExperts = ({ data }) => {
   return (
        <>
            <NeedExpertsSection >
                <NeedExpertsWrapper >
                    <SectionOne>
                        <BackgroundImage src={data.asset} />
                        <div>
                            <NeedExpertsBody>{data.body}</NeedExpertsBody>
                            <NeedExpertsLink to='#' key='1' onClick={(e) => {
                                window.location.href = data.btnLink;
                                e.preventDefault();
                            }}>{data.btnLabel}</NeedExpertsLink>
                        </div>
                    </SectionOne>
                    <SectionTwo>
                        <ActionLinkBtn key='0' to='#'>{data.actionBtnLabel1}</ActionLinkBtn>
                        <ActionLinkBtn key='1' to='#'>{data.actionBtnLabel2}</ActionLinkBtn>
                    </SectionTwo>
                </NeedExpertsWrapper>
            </NeedExpertsSection>
        </>
    )
}

export default NeedExperts;