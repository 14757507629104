import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Styles from '../css/styles.module.css';
import { Link } from 'react-router-dom';
import { menuData } from '../../data/MenuData';
import { Button } from './Button';
import companyLogo from '../../images/logo-arize.svg';
import { FaBars } from 'react-icons/fa';

const Nav = styled.nav`
    background: rgba(41, 41, 50, 0.85);
    backdrop-filter: blur(5px);
    border-radius: 12px;
    display: flex;
    height: 60px;
    justify-content: space-between;
    margin: 10px;
    margin-bottom: 0;
    width: calc(100% - 20px);
    padding: 17px;
    position: fixed;
    z-index: 2;
    top: 0;

    @media screen and (max-width: 768px) {
        border-radius: 5px;
    }
`;

const NavWrapper = styled.div`
    height: 80px;
    position: fixed;
    z-index: 999;
`;

const MenuBars = styled(FaBars)`
    color: white;
    cursor: pointer;
    display: none;
    font-size: 24px;
    height: 2.5rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const NavMenu = styled.div`
    align-items: center;
    display: flex;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Logo = styled(Link)`
    background-image: url(${companyLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    height: 34px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 74px;
`;

const NavMenuLinks = styled(Link)`
    color: #ffffff;
    margin: 0 1.5rem;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin: 0 0.5rem;
    }

    &.selected {
        color: #00FFC2;
        cursor: auto;
        pointer-events: none;
    }
`;

const NavBtn = styled.div`
    align-items: center;
    display: flex;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Navbar = ({toggle, pageID}) => {
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top < 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    return (
        <NavWrapper ref={ref}>
            <Nav>
                <Logo to="/home" />
                <MenuBars onClick={toggle}></MenuBars>
                <NavMenu>
                    {menuData.map((item, index) => (
                        <NavMenuLinks className={Styles.standardNavLink + '' + (pageID === index ? ' selected' : '')} to={item.link} key={index}>
                            {item.title}
                        </NavMenuLinks>
                    ))}
                </NavMenu>
                <NavBtn>
                    <Button to='#' onClick={(e) => {
                        window.location.href = 'mailto:hello@arize.co.in';
                        e.preventDefault();
                    }} primary='true'>Start a project</Button>
                </NavBtn>
            </Nav>
        </NavWrapper>
    )
};

export default Navbar;
