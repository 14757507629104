import React from 'react';
import styled from 'styled-components';
import Styles from '../../css/styles.module.css';
import {Link } from "react-router-dom";

const ExpertiesSection = styled.section`
    background: #1D1D1D;
    float: left;
    position: relative;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 1049px) {
        
    }
`;

const ExpertiesWrapper = styled.div`
    overflow: hidden;
    padding: 0 0 80px;
    position: relative;
    text-align: center;
    width: 100%;
`;

const BlockOne = styled.div`
    margin: 0 auto 80px auto;
    max-width: 880px;
    padding: 0 10px;

    // @media screen and (max-width: 768px) {
    //     padding: 0 15px;
    // }

    .title {
        margin: 70px 0 30px;
    }
`;
const BlockOneTitle = styled.div`
    color: #FFFFFF !important;
`;
const BlockOneBody = styled.div`
    color: #FFFFFF !important;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const BlockTwo = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1070px;
    padding: 0 10px;
`;
const ExpertiesItem = styled(Link)`
    background: #1D1D1D;
    aspect-ratio: 1 / 1;
    border: 1px solid #B67DFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    max-width: 350px;
    padding: 30px;
    text-decoration: none;
    text-transform: uppercase;
    width: calc(100% / 3);

    &:hover {
        background: radial-gradient(circle at top left, rgb(105, 102, 255) 0%,rgb(151, 92, 229) 38%,rgb(180, 73, 137) 75%,rgb(249, 114, 116) 100%);
        
        .icon {
            // filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(246deg) brightness(200%) contrast(107%);
            filter: brightness(250%);
            transform: scale(1.25);
            transform-origin: top left;
        }
        .label {
            font-size: 24px;
            font-weight: 500;
        }
    }
    @media screen and (min-width: 769px) {
        &:nth-child(1) {
            border-bottom: none;
            border-right: none;
            border-top-left-radius: 10px;
        }
        &:nth-child(2) {
            border-bottom: none;
        }
        &:nth-child(3) {
            border-bottom: none;
            border-left: none;
            border-top-right-radius: 10px;
        }
        &:nth-child(4) {
            border-right: none;
            border-bottom-left-radius: 10px;
        }
        &:nth-child(6) {
            border-left: none;
            border-bottom-right-radius: 10px;
        }
    }
    @media screen and (max-width: 768px) {
        align-items: center;
        aspect-ratio: inherit;
        flex-direction: row;
        gap: 1rem;
        justify-content: left;
        max-width: 100%;
        padding: 30px 20px;
        width: 100%;

        &:first-child {
            border-bottom: none;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &:hover {
            .icon {
                transform: inherit;
                // transform-origin: inherit;
            }
            .label {
                font-size: 27px;
                font-weight: 500;
            }
        }

        .icon {
            max-width: 40px;
        }
        .label {
            font-size: 25px;
            font-weight: 300;
        }
    }
`;
const Icon = styled.img`
    width: fit-content;
`;
const Title = styled.div`
    color: #FFFFFF;
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    text-align: left;
`;

const Experties = ({ data }) => {
  return (
    <>
        <ExpertiesSection >
            <ExpertiesWrapper >
                {data.title || data.body ? <BlockOne>
                    {data.title ? <BlockOneTitle className={'title '+Styles.standardTitle}>{data.title}</BlockOneTitle> : null}
                    {data.body ? <BlockOneBody className={Styles.standardBody}>{data.body}</BlockOneBody> : null}
                </BlockOne> : null}
                <BlockTwo className='blockTwo'>
                    {data.items.map((item, index) => {
                        return (
                            <ExpertiesItem key={index} to="#">
                                <Icon className={'icon'} src={item.asset} />
                                <Title className={'label'}>{item.title}</Title>
                            </ExpertiesItem>
                        )
                    })}
                </BlockTwo>
            </ExpertiesWrapper>
        </ExpertiesSection>
    </>
  )
}

export default Experties;