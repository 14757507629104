import React, { useState, useEffect } from 'react';
// import IntersectionVisible from 'react-intersection-visible';
import { isMobile } from 'react-device-detect';
import styled, { keyframes } from 'styled-components/macro';
import Marquee from 'react-fast-marquee';

import { Data } from '../../data/AboutUsData';

import AboutUsHero from '../layouts/aboutus/AboutUsHero';
import Openings from '../layouts/Openings';
import Footer from '../layouts/Footer';
import HTMLText from '../layouts/HTMLText';
import Styles from '../css/styles.module.css';
import '../css/testimonials.css';

import ScrollToTopOnMount from '../ScrollToTop';
import { Link } from 'react-router-dom';

const SlideIn = keyframes`
    0%   {top: 100vh}
    100% {top: 0}
`
const SectionsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled.section`
  // float: left;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 10px;
  position: relative;
  width: 100%;

  &.section1 {
    margin: 0 auto;
    max-width: 800px;
    padding: 60px 10px;
    text-align: center;
  }
  &.section2, &.section3, &.section4, &.section5, &.section6, &.section8, &.section9 {
    max-width: 100%;
    padding: 0;
  }
  &.section7 {
    margin-bottom: 100px
  }
  &.section2 {
    .slideIn {
        margin: 0 auto;

        img {
            max-width: 256px;
            position: relative;
            top: 100vh;
        }
        &.animate-in img {
            animation: 1s linear 1  ${SlideIn} forwards;
        }
    }
  }
`;
const OurPriority = styled.div`
  // background: #ECECEC;
  float: left;
  padding: 60px 0;
  position: relative;
  width: 100%;
`
const OurPriorityInner = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 720px;
  overflow: hidden;
  padding: 0 10px 3rem;
  position: relative;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`
const OurValuesContainer = styled.div`
  background-color: #000000;
  margin: 0 auto;
  max-width: 1200px;
  padding: 88px 0;
`
const OurValuesContainerInner = styled.div`
  padding: 0 88px;
`
const OurValuesItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 88px;
  padding-top: 88px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .valueItem {
    font-family: 'sharp-grotesk-book';
    font-size: 18px;

    .bold {
      font-family: 'sharp-grotesk';
      font-weight: 400;
    }
  }
`
const LeadershipContainer = styled.div`
  background-color: #E1E0E7;
  margin: 0 auto;
  max-width: 1200px;
  padding: 88px 0;
`
const LeadershipContainerInner = styled.div`
  padding: 0 88px;
`
const LeaderCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 90px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 645px;
  padding-top: 88px;

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }

  .valueItem {
    font-family: 'sharp-grotesk-book';
    font-size: 18px;

    .bold {
      font-family: 'sharp-grotesk';
      font-weight: 600;
    }
  }
`
const LogoImg = styled.img``
const LogoBtn = styled(Link)`
    text-decoration: none;
`
const TestimonialsContainer = styled.div`
  background-color: #E1E0E7;
  margin: 0 auto;
  max-width: 1200px;
  padding: 88px 10px;
`
const TestimonialsContainerInner = styled.div`
  // padding: 0 88px;
`
const Testimonial = styled.div`
  background-color: #fff;
  border-radius: 40px;
  color: #3D3D3D;
  font-size: 18px;
  font-weight: 500;
  padding: 2rem;
  width: 385px;
`
const OurCustomersContainer = styled.div`
  // background-color: #E1E0E7;
  margin: 0 auto;
  max-width: 1200px;
  padding: 188px 10px;
`
const OurCustomersContainerInner = styled.div`
  // padding: 0 88px;
`
const OurCustomers = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-around;
  margin: 2rem auto;
  max-width: 970px;
  row-gap: 0;
`
const Customer = styled.div`
  height: 118px;

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`
const BlockContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 245px;
  padding: 45px;
  width: 50%;

  &.block1 {
    background: #6966FF;
  }
  &.block2 {
    background: #DFE9F0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const ActionButton = styled(Link)`
  align-items: center;
  background: ${({ transparentbg }) => (transparentbg ? "none" : "#000")};
  // border: ${({ transparentbg }) => (transparentbg ? "2px solid #000" : "none")};
  border: ${({ transparentbg }) => (transparentbg ? "2px solid #000" : "2px solid #6966FF")};
  border-radius: 35px;
  color: ${({ transparentbg }) => (transparentbg ? "#000" : "#FFF")};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  padding: 13px;
  position: relative;
  text-decoration: none;
  transition: 0.3s;
  width: 190px;
  white-space: nowrap;
  z-index: 5;

  &:after {
    background: linear-gradient(to right top, #6966ff, #7464fa, #7e62f6, #8660f1, #8e5eec, #a859e0, #bc54d4, #cc51c7, #e44faf, #f25698, #f96284, #f97274);
    content: '';    
    display: block;
    height: 100%;
    position: absolute;
    top: 0; 
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -5;
    transition: opacity 1s;
    border-radius: 35px;
  }

  &:hover {
    border-color: ${({ transparentbg }) => (transparentbg ? "transparent" : "#6966FF")};
    color: #FFF;

    &:after {
        opacity: 1;
    }
  }
`
const PriorityImg = styled.img`
    // padding-top: 140px;
    margin-right: 35px;
    max-width: 251px;

    @media screen and (max-width: 768px) {
      margin: 0;
    }
`

const AboutUs = ({ setPageID }) => {
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    console.log("check")
    setActive(!isActive);
  }
  useEffect(() => {
    setPageID(3);

    // return () => {
    //     setPageID(2);
    // };
  }, []);
  // const onShow = (entries) => {
  //   if (!entries[0].target.classList.contains('animate-in')) {
  //     entries[0].target.classList.add('animate-in');
  //   }
  // }
  return (
    <>
      <ScrollToTopOnMount />
      <AboutUsHero data={Data.home} />
      <SectionsContainer>
        <SectionWrapper className="section section1">
          <HTMLText classes={'title ' + Styles.standardTitle} htmlString={Data.ourStory.title} />
          <HTMLText classes={Styles.standardBody} styles={{ fontWeight: "300" }} htmlString={Data.ourStory.body} />
        </SectionWrapper>
        <SectionWrapper className="section section2">
          <OurPriority>
            <OurPriorityInner>
              {/* <IntersectionVisible options={{ threshold: 1 }} className='slideIn' onShow={e => onShow(e)}> */}
              <PriorityImg src={Data.ourPriority.asset} />
              {/* </IntersectionVisible> */}
              <HTMLText classes={Styles.priorityText + ' ' + Styles.standardBody} htmlString={Data.ourPriority.body} />
            </OurPriorityInner>
          </OurPriority>
        </SectionWrapper>
        <SectionWrapper className="section section3">
          <OurValuesContainer>
            <OurValuesContainerInner>
              <HTMLText classes={'title ' + Styles.standardTitle} styles={{ color: "#FFF", marginBottom: "5px", textAlign: "center" }} htmlString={Data.ourValues.title} />
              <OurValuesItem>
                {Data.ourValues.items.map((item, index) => {
                  return (
                    <div className='valueItem' key={index}>
                      <img className={'itemIcon'} src={item.asset} />
                      <HTMLText styles={{ color: "#FFF", marginBottom: "5px" }} htmlString={item.body} />
                    </div>
                  )
                })}
              </OurValuesItem>
            </OurValuesContainerInner>
          </OurValuesContainer>
        </SectionWrapper>
        <SectionWrapper className="section section4">
          <LeadershipContainer>
            <LeadershipContainerInner>
              <HTMLText styles={{ color: "#000", marginBottom: "5px", fontSize: '18px', fontWeight: '600', textAlign: "center" }} htmlString={Data.leadership.title} />
              <LeaderCards>
                {Data.leadership.items.map((item, index) => {
                  return (
                    <div className='leaderCard' key={index}>
                      <img className={'leaderImage'} src={item.asset} />
                      <HTMLText styles={{ color: "#000", fontSize: '18px', fontWeight: '500' }} htmlString={item.name} />
                      <HTMLText styles={{ color: "#000", fontSize: '18px', fontWeight: '300', marginBottom: "15px" }} htmlString={item.title} />
                      <LogoBtn to='#' onClick={(e) => {
                        window.open(item.socialUrl, '_blank');
                        e.preventDefault();
                      }}><LogoImg src={item.socialPlatform} /></LogoBtn>
                    </div>
                  )
                })}
              </LeaderCards>
            </LeadershipContainerInner>
          </LeadershipContainer>
        </SectionWrapper>
        <SectionWrapper className='section5'>
          <TestimonialsContainer>
            <TestimonialsContainerInner>
              <HTMLText styles={{ color: "#3D3D3D", fontSize: '22px', fontWeight: '300', marginBottom: "5px", textAlign: "center" }} htmlString={Data.testimonials.title} />
              {isMobile ? (
                <div
                  className={isActive ? 'testimonials active' : 'testimonials'}
                  onTouchStart={(e) => {
                    e.preventDefault();
                    setActive(!isActive);
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                    setActive(!isActive);
                  }}
                >
                  <Marquee autoFill className={'marquee-container'} speed='50'>
                    {Data.testimonials.items.map((item, index) => {
                      return (
                        <Testimonial key={index}>{item.body}</Testimonial>
                      )
                    })}
                  </Marquee>
                </div>
              ) : (
                <div className='testimonials'>
                  <Marquee autoFill pauseOnHover className={'marquee-container'} speed='50'>
                    {Data.testimonials.items.map((item, index) => {
                      return (
                        <Testimonial key={index}>{item.body}</Testimonial>
                      )
                    })}
                  </Marquee>
                </div>
              )}
            </TestimonialsContainerInner>
          </TestimonialsContainer>
        </SectionWrapper>
        <SectionWrapper className='section6'>
          <OurCustomersContainer>
            <OurCustomersContainerInner>
              <HTMLText styles={{ color: "#3D3D3D", fontSize: '18px', fontWeight: '600', marginBottom: "30px", textAlign: "center" }} htmlString={Data.ourCustomers.title} />
              <HTMLText styles={{ color: "#3D3D3D", fontSize: '22px', fontWeight: '300', margin: "0 auto 5px", maxWidth: '725px', textAlign: "center" }} htmlString={Data.ourCustomers.body} />
              <OurCustomers>
                {Data.ourCustomers.items.map((item, index) => {
                  return (
                    <Customer key={index}>
                      <img className={'customerLogo'} src={item.asset} />
                    </Customer>
                  )
                })}
              </OurCustomers>
            </OurCustomersContainerInner>
          </OurCustomersContainer>
        </SectionWrapper>
        <SectionWrapper className='section7'>
          <BlockContainer>
            <Block className='block1'>
              <div className='content'>
                <HTMLText classes={'title ' + Styles.standardTitle} styles={{ color: "#FFF", marginBottom: "5px" }} htmlString={Data.aboutUs.block1.title} />
                <HTMLText classes={Styles.standardBody} styles={{ color: "#FFF", marginBottom: "5px" }} htmlString={Data.aboutUs.block1.body} />
              </div>
              <div className='button'>
                <ActionButton to='#'>{Data.aboutUs.block1.button.label}</ActionButton>
              </div>
            </Block>
            <Block className='block2'>
              <div className='content'>
                <HTMLText classes={'title ' + Styles.standardTitle} styles={{ color: "#000", marginBottom: "5px" }} htmlString={Data.aboutUs.block2.title} />
                <HTMLText classes={Styles.standardBody} styles={{ color: "#000", marginBottom: "5px" }} htmlString={Data.aboutUs.block2.body} />
              </div>
              <div className='button'>
                <ActionButton to='#' transparentbg='true'>{Data.aboutUs.block2.button.label}</ActionButton>
              </div>
            </Block>
          </BlockContainer>
        </SectionWrapper>
        <SectionWrapper className='section section8' style={{ borderBottom: "2px solid #FFFFFF" }}>
          <Openings data={Data.openings} />
        </SectionWrapper>
        <SectionWrapper className='section section9'>
          <Footer pageID={3} />
        </SectionWrapper>
      </SectionsContainer>
    </>
  )
}

export default AboutUs