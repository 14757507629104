import React, {useState} from 'react';
import styled, { css } from 'styled-components/macro';
import Styles from '../../css/styles.module.css';

const FlexBoxRow = css`
    display: flex;
    flex-direction: row;
`
const FlexBoxColumn = css`
    display: flex;
    flex-direction: column;
`
const ComponentWrapper = styled.div`
    margin: 6.8rem auto 3.8rem;
    // max-width: 1200px;
    width: 100%;
    
    @media screen and (max-width: 768px) {
        margin: 3rem auto;
    }
`
const LocationsTitle = styled.div`
    margin-bottom: 0;
    padding: 0 0 3.5rem 0;
    text-align: center;

    @media screen and (max-width: 768px) {
        padding: 0 0 3rem 0;
    }
`
const LocationsHolder = styled.div`
    ${FlexBoxColumn};
`
const LocationItem = styled.div`
    ${FlexBoxColumn};
    padding-bottom: 1rem;
`
const ItemBlock = styled.div`
    ${FlexBoxRow};
    column-gap: 75px;
    padding: 0 35px;

    & > * {
        flex: 0 100%;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 25px;
    }
`
const Seperator = styled.img`
    padding: 33px 0;
    width: 100%;
`
const City = styled.div`
    align-items: end;
    display: flex;
    flex-direction: column;
    max-width: 500px;

    @media screen and (min-width: 769px) {
        .cityTime, .timeUnit {
            display: block;
        }
    }
    @media screen and (max-width: 768px) {
        align-items: start;
    }
`
const CityName = styled.div`
    font-family: 'sharp-grotesk25', sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.6;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        padding-left: 10px;
    }
`
const CityTime = styled.div`
    display: none;
    font-family: 'sharp-grotesk25', sans-serif;
    font-size: 22px;
    font-weight: 100;
    padding-top: 5px;
`
const TimeUnit = styled.div`
    display: none;
    color: #828282;
    font-size: 11px;
    font-weight: 100;
`
const Address = styled.div`
    ${FlexBoxColumn};
    @media screen and (min-width: 1200px) {
        max-width: 40%;
    }
    @media screen and (max-width: 768px) {
        .cityTime, .timeUnit {
            display: block;
        }
    }

    & > * {
        flex: 0 100%;
    }
`
const StyledUL = styled.ul`

`

const StyledLI = styled.li`
    font-weight: 300;
    line-height: 1.6;
    padding-left: 10px;

    // &::marker {
    //     font-size: 12px;
    // }
`

const ContactUsLocations = ({ data }) => {
    const [indiaTime, setIndiaTime] = useState('');
    const [canadaTime, setCanadaTime] = useState('');
    const [londonTime, setUKTime] = useState('');

    const updateTime = () => {
        let date = new Date();
        let hours = date.getHours();
        let min = date.getMinutes();
        let time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min);

        setIndiaTime(time);

        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let canadaDate = new Date(utc + (3600000*-4));

        hours = canadaDate.getHours();
        min = canadaDate.getMinutes();
        time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min);

        setCanadaTime(time);

        let Gtc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let UKDate = new Date(Gtc + (3600000));

        hours = UKDate.getHours();
        min = UKDate.getMinutes();
        time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min);

        setUKTime(time);
    }
    const executeInterval = function() {
        updateTime();
        setInterval(updateTime, 30000);
    }

    setTimeout(executeInterval, 500);
    return (
        <>
            <ComponentWrapper>
                <LocationsTitle className={'title '+Styles.standardTitle}>{data.title}</LocationsTitle>
                <LocationsHolder>
                    {data.items.map((item, index) => {
                        return (
                            <LocationItem key={index}>
                                <ItemBlock>
                                    <City>
                                        <CityName>{item.location}</CityName>
                                        <CityTime className='cityTime'>{item.timezone === 'india' ? indiaTime : item.timezone === 'london' ? londonTime : canadaTime}</CityTime>
                                        <TimeUnit className='timeUnit'>{item.timeunit}</TimeUnit>
                                    </City>
                                    <Address>
                                        <StyledUL>
                                            <StyledLI>{item.address}</StyledLI>
                                            <StyledLI>{item.phone}</StyledLI>
                                        </StyledUL>
                                        <div style={{paddingLeft: '10px', paddingTop: '20px', width: "fit-content"}}>
                                            <CityTime className='cityTime'>{item.timezone === 'india' ? indiaTime : item.timezone === 'london' ? londonTime : canadaTime}</CityTime>
                                            <TimeUnit className='timeUnit' style={{textAlign: 'right'}}>{item.timeunit}</TimeUnit>
                                        </div>
                                    </Address>
                                </ItemBlock>
                                <Seperator src={item.seperator} />
                            </LocationItem>
                        )
                    })}
                </LocationsHolder>
            </ComponentWrapper>
        </>
     )
 }
 
 export default ContactUsLocations;