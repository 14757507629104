import React from 'react'
import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa';
import { menuData } from '../data/MenuData'
import { Link } from 'react-router-dom'
import { Button } from './layouts/Button'

const DropdownContainer = styled.div`
    align-items: center;
    background: rgba(41, 41, 50, 0.9);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    display: none;
    height: calc(100vh - 20px);
    // max-width: 350px;
    opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
    padding: 2rem;
    position: fixed;
    right: 10px;
    top: 10px;
    transform: ${({isOpen}) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
    // top: ${({isOpen}) => (isOpen ? '80px' : '-100%')};
    transition: 0.3s ease-in-out;
    width: calc(100% - 20px);
    z-index: 999;

    @media screen and (max-width: 768px) {
        display: grid;
    }

    // @media screen and (max-width: 768px) {
    //     right: 0;
    //     top: 77px;
    // }
`

const DropdownWrapper = styled.div``

const DropdownMenu = styled.div`
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 50px);
    text-align: left;
    height: calc(100vh - 124px);
`

const DropdownLink = styled(Link)`
    color: #ffffff;
    custor: pointer;
    font-size: 36px;
    font-weight: 300;
    list-style: none;
    text-decoration: none;
    transition: 0.2s ease-in-out;

    &:hover {
        color: #00FFC2;
    }

    &.selected {
        color: #00FFC2;
        cursor: auto;
        pointer-events: none;
    }

    @media screen and (max-width: 400px) {
        font-size: 20px;
    }
`

const BtnWrap = styled.div`
    background-color: #282828;
    border-radius: 35px;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;

    a {
        font-family: 'sharp-grotesk-book', sans-serif;
        font-size: 36px;
        padding: 20px 30px;
        width: inherit;
    }

    @media screen and (max-width: 400px) {
        font-size: 20px;

        a {
            font-size: 20px;
        }
    }
`
const MenuClose = styled(FaTimes)`
    color: white;
    cursor: pointer;
    font-size: 24px;
    height: 2.5rem;
    position: absolute;
    right: 15px;
    top: 3px;
    // transform: translateY(-50%);

    // @media screen and (max-width: 768px) {
    //     display: block;
    // }
`

const Dropdown = ({isOpen, toggle, pageID}) => {
  return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
        <DropdownWrapper>
            <DropdownMenu>
                {menuData.map((item, index) => (
                    <DropdownLink to={item.link} key={index} className={(pageID === index ? ' selected' : '')}>
                        {item.title}
                    </DropdownLink>
                ))}
            </DropdownMenu>
            <BtnWrap>
                <Button dropdown='true' to='/startProject'>Start a project</Button>
            </BtnWrap>
            <MenuClose onClick={toggle}></MenuClose>
        </DropdownWrapper>
    </DropdownContainer>
  )
}

export default Dropdown