import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom';
import Styles from '../css/styles.module.css';

import ISOCertificatesImg from '../../images/iso-certificates.png';
import LinkedInImg from '../../images/LinkedIn.svg';
import XImg from '../../images/x.svg';
import InstagramImg from '../../images/instagram.svg';
import ArizeLogo from '../../images/arize-logo-footer.png';

const FlexBoxRow = css`
    display: flex;
    flex-direction: row;
`
const FlexBoxColumn = css`
    display: flex;
    flex-direction: column;
`
const FooterSection = styled.section`
    background: #E4E1F1;
    float: left;
    padding: 3rem 0;
    position: relative;
    width: 100%;
`;
const FooterWrapper = styled.div`
    ${FlexBoxColumn};
    margin: 0 auto;
    // max-width: 1220px;
    // padding: 0 10px;
    max-width: 1200px;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

const ProjectBtn = styled(Link)`
    background: radial-gradient(ellipse at bottom left,rgb(105, 102, 255) 0%,rgb(151, 92, 229) 48%,rgb(249, 114, 116) 100%);
    // background-size: 645px 105px;
    border-radius: 35px;
    color: #ffffff;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    max-height: 55px;
    max-width: 210px;
    order: 1;
    padding: 15px 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    z-index: 5;

    &:after {
        background: radial-gradient(ellipse at bottom left,rgb(249, 114, 116) 0%,rgb(151, 92, 229) 48%,rgb(105, 102, 255) 100%);
        content: '';    
        display: block;
        height: 100%;
        position: absolute;
        top: 0; 
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -5;
        transition: opacity 1s;
        border-radius: 35px;
    }

    &:hover {
        // opacity: 0.76;
        &:after {
            opacity: 1;
        }
    }

    @media screen and (max-width: 768px) {
        order: 0;
    }
    @media screen and (max-width: 470px) {
        font-size: 17px;
    }
`
const BlockOne = styled.div`
    ${FlexBoxRow};
    gap: 1rem;
    width: 100%;

    & > * {
        flex: 1 100%;
    }

    .btnHolder {
        display: inherit;
        flex: 1 auto;
        max-width: 210px;
        order: 1;
        padding: 0 0 3rem;
        width: 100%;
    }

    @media screen and (max-width: 1220px) {
        .btnHolder {
            max-width: 240px;
            padding: 0 30px 3rem 0;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;

        .btnHolder {
            border-bottom: 2px solid #FFFFFF;
            flex: 1 100%;
            justify-content: center;
            max-width: inherit;
            padding: 0 0 3rem 0;
            order: 0;
        }
    }
    // @media screen and (max-width: 520px) {
    //     .btnHolder {
    //         padding: 0 10px 3rem;
    //     }
    // }
`
const BlockOneContent = styled.div`
    ${FlexBoxColumn};
    gap: 1rem;
    padding: 0;
    text-transform: uppercase;

    @media screen and (max-width: 1220px) {
        padding: 0 30px;
    }
    @media screen and (max-width: 768px) {
        padding: 3rem 30px;
    }
    @media screen and (max-width: 520px) {
        padding: 3rem 10px;
    }
`
const BlockRow = styled.div`
    ${FlexBoxRow};
    gap: 1rem;
`
const RowLinks = styled.div`
    ${FlexBoxRow};
    flex-wrap: wrap;
    gap: 1rem;
    row-gap: 5px;
`
const RowTitle = styled.div`
    font-size: 14px;
    font-weight: 300;
`
const RowLink = styled(Link)`
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    text-underline-offset: 0.35em;

    &:hover {
        text-decoration: underline;
    }

    &.selected {
        pointer-events: none;
        text-decoration: underline;
    }
`
const BlockTwo = styled.div`
    column-gap: 0.5rem;
    ${FlexBoxRow};
    justify-content: end;
    margin-top: 45px;

    & > * {
        flex: 0 auto;
    }

    @media screen and (max-width: 768px) {
        margin-top: 0;
        padding-bottom: 3rem;
    }
`
const BlockTwoSectionOne = styled.div`
    @media screen and (max-width: 768px) {
        display: none;
    }
`
const BlockTwoSectionTwo = styled.div`
    ${FlexBoxColumn};
    align-items: end;
    padding: 25px 0 0;

    @media screen and (max-width: 1220px) {
        padding: 25px 30px 0 0;
    }

    @media screen and (max-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 30px;
        width: 100%;

        // & > * {
        //     flex: 1 100%;
        // }
    }
    @media screen and (max-width: 520px) {
        padding: 0 10px;
    }
`
const BlockTwoRowOne = styled.div`
    ${FlexBoxRow};
    justify-content: center;
    gap: 1rem;
    
    @media screen and (max-width: 450px) {
        gap: 9px;
    }
`
const BlockTwoRowTwo = styled.div`
    ${FlexBoxColumn};
    align-items: end;
    row-gap: 2px;

    &:last-child {
        padding-top: 1.5rem;
    }

    div {
        ${FlexBoxRow};
        gap: 0.5rem;
    }

    @media screen and (max-width: 768px) {
        &:last-child {
            padding-top: 0;
        }
    }
`
const LogoImg = styled.img``
const LogoBtn = styled(Link)`
    text-decoration: none;
`
const BlockTwoLink = styled(Link)`
    color: #000000;
    font-size: 12px;
    font-weight: 100;
    text-transform: uppercase;
    text-underline-offset: 0.35em;

    &:hover {
        text-decoration: none;
    }
`
const BlockThree = styled.div`
    ${FlexBoxColumn};

    div:last-child {
        padding-top: 7px;
    }

    @media screen and (max-width: 1220px) {
        padding: 0 30px;
    }

    @media screen and (max-width: 520px) {
        padding: 0 10px;
    }
`
const TrademarkWordings = styled.div`
    color: #000000;
    font-size: 10px;
    font-weight: 100;
`

const Footer = ({ pageID }) => {
    return (
        <>
            <FooterSection className={Styles.standardSection}>
                <FooterWrapper className={Styles.standardWrapper}>
                    <BlockOne>
                        <div className='btnHolder'>
                            <ProjectBtn to='#' onClick={(e) => {
                                window.location.href = 'mailto:hello@arize.co.in';
                                e.preventDefault();
                            }} primary='true'>Start a project</ProjectBtn>
                        </div>
                        <BlockOneContent>
                            <BlockRow>
                                <RowTitle>Services</RowTitle>
                                <RowLinks>
                                    <RowLink to='#'>Content</RowLink>
                                    <RowLink to='#'>Platforms</RowLink>
                                    <RowLink to='#'>Products</RowLink>
                                    <RowLink to='#'>Design</RowLink>
                                    <RowLink to='#'>User Experience</RowLink>
                                    <RowLink to='#'>Consultancy</RowLink>
                                </RowLinks>
                            </BlockRow>
                            <BlockRow>
                                <RowTitle>Company</RowTitle>
                                <RowLinks>
                                    <RowLink to='/about-us' className={(pageID === 3 ? ' selected' : '')}>About</RowLink>
                                    <RowLink to='#' className={(pageID === 4 ? ' selected' : '')}>Careers</RowLink>
                                    <RowLink to='/contact-us' className={(pageID === 5 ? ' selected' : '')}>Contact</RowLink>
                                    <RowLink to='/approach' className={(pageID === 2 ? ' selected' : '')}>Approach</RowLink>
                                </RowLinks>
                            </BlockRow><BlockRow>
                                <RowTitle>Support</RowTitle>
                                <RowLinks>
                                    <RowLink to='#'>General Queries</RowLink>
                                    <RowLink to='#'>Existing Project Support</RowLink>
                                    <RowLink to='#'>Billing/Invoicing Issues</RowLink>
                                </RowLinks>
                            </BlockRow>
                        </BlockOneContent>
                    </BlockOne>
                    <BlockTwo>
                        <BlockTwoSectionOne>
                            <LogoImg src={ISOCertificatesImg} />
                        </BlockTwoSectionOne>
                        <BlockTwoSectionTwo>
                            <BlockTwoRowOne>
                                <LogoBtn to='#'><LogoImg src={LinkedInImg} /></LogoBtn>
                                <LogoBtn to='#'><LogoImg src={XImg} /></LogoBtn>
                                <LogoBtn to='#'><LogoImg src={InstagramImg} /></LogoBtn>
                            </BlockTwoRowOne>
                            <BlockTwoRowTwo>
                                <div style={{ fontFamily: 'sharp-grotesk-book', fontSize: '14px' }}>© Arize Digital Pvt. Ltd. 2023</div>
                                <div>
                                    <BlockTwoLink to='#'>Privacy</BlockTwoLink>
                                    <BlockTwoLink to='#'>Cookies</BlockTwoLink>
                                    <BlockTwoLink to='#'>UK GDPR</BlockTwoLink>
                                </div>
                            </BlockTwoRowTwo>
                        </BlockTwoSectionTwo>
                    </BlockTwo>
                    <BlockThree>
                        <LogoImg src={ArizeLogo} style={{ maxWidth: '320px' }} />
                        <TrademarkWordings>The Arize Digital logo is trademark of Arize Digital Pvt. Ltd., registered in India. Other company and product names mentioned herein may be trademarks of their respective companies.</TrademarkWordings>
                        <TrademarkWordings>CIN: U74999RJ2021PTC078587     GST:08AAWCA2392J1Z2 </TrademarkWordings>
                    </BlockThree>
                </FooterWrapper>
            </FooterSection>
        </>
    )
}

export default Footer;