import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { Data } from '../../data/ApproachData';

import ApproachHero from '../layouts/approach/ApproachHero';
import Openings from '../layouts/Openings';
import Footer from '../layouts/Footer';
import HTMLText from '../layouts/HTMLText';
import Styles from '../css/styles.module.css';

import ScrollToTopOnMount from '../ScrollToTop';
import TalkWithUs from '../layouts/home/TalkWithUs';
import { Link } from 'react-router-dom';
import ApproachAnimation from '../layouts/ApproachAnimation';

const SectionsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled.section`
  // float: left;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 10px;
  position: relative;
  width: 100%;

  &.section1 {
    margin: 0 auto;
    max-width: 764px;
    padding: 60px 10px;
    text-align: center;
  }
  &.section2, &.section3, &.section6, &.section7 {
    max-width: 100%;
    padding: 0;
  }
  &.section5 {
    margin-bottom: 100px
  }
`;
const OurApproach = styled.div`
  background: #ECECEC;
  float: left;
  padding: 60px 0;
  position: relative;
  width: 100%;
`
const OurApproachInner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 764px;
  overflow: hidden;
  padding: 0 10px 3rem;
  position: relative;
  text-align: center;
  width: 100%;
`
const ApproachBanner = styled.img`
  margin: 0 auto;
  max-width: 615px;
  padding-top: 2rem;
  width: 100%;
`
const ApproachQuotation = styled.div`
  box-shadow: inset 0px 100px 100px 100px rgba(199,222,241,0.8), 0px 0px 60px 35px rgba(199,222,241,0.95);
  border-radius: 15px;
  left: 50%;
  max-width: 764px;
  padding: 7px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
`
const BlockContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 245px;
  padding: 45px;
  width: 50%;

  &.block1 {
    background: #6966FF;
  }
  &.block2 {
    background: #DFE9F0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const ActionButton = styled(Link)`
  align-items: center;
  background: ${({transparentbg}) => (transparentbg ? "none" : "#000")};
  // border: ${({transparentbg}) => (transparentbg ? "2px solid #000" : "none")};
  border: ${({transparentbg}) => (transparentbg ? "2px solid #000" : "2px solid #6966FF")};
  border-radius: 35px;
  color: ${({transparentbg}) => (transparentbg ? "#000" : "#FFF")};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  padding: 13px;
  position: relative;
  text-decoration: none;
  transition: 0.3s;
  width: 190px;
  white-space: nowrap;
  z-index: 5;

  &:after {
    background: linear-gradient(to right top, #6966ff, #7464fa, #7e62f6, #8660f1, #8e5eec, #a859e0, #bc54d4, #cc51c7, #e44faf, #f25698, #f96284, #f97274);
    content: '';    
    display: block;
    height: 100%;
    position: absolute;
    top: 0; 
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -5;
    transition: opacity 1s;
    border-radius: 35px;
  }

  &:hover {
    border-color: ${({transparentbg}) => (transparentbg ? "transparent" : "#6966FF")};
    color: #FFF;

    &:after {
        opacity: 1;
    }
  }
`

const Approach = ({setPageID}) => {
  useEffect(() => {
    setPageID(2);

    // return () => {
    //     setPageID(2);
    // };
  }, []);
  return (
    <>
      <ScrollToTopOnMount />
      <ApproachHero data={Data.home} />
      <SectionsContainer>
        <SectionWrapper className="section section1">
          <HTMLText classes={'title '+Styles.standardTitle} htmlString={Data.ourApproach.title} />
          <HTMLText classes={Styles.standardBody} htmlString={Data.ourApproach.body} />
        </SectionWrapper>
        <SectionWrapper className="section section2">
          <OurApproach>
            <OurApproachInner>
              <HTMLText classes={'title '+Styles.standardTitle} htmlString={Data.ourDrive.title} />
              <HTMLText classes={Styles.standardBody} htmlString={Data.ourDrive.body} />
              <ApproachAnimation />
            </OurApproachInner>
          </OurApproach>
        </SectionWrapper>
        <SectionWrapper className="section section3">
          <ApproachBanner src={Data.quote.asset} style={{height: "762px", maxWidth: "none", minWidth: "100%", padding: "0", width: "auto"}}></ApproachBanner>
          <ApproachQuotation>
            <HTMLText classes={'title '+Styles.standardTitle} styles={{fontSize: "20px", fontWeight: "500", marginBottom: "5px"}} htmlString={Data.quote.title} />
            <HTMLText styles={{fontSize: "18px", fontWeight: "100"}} htmlString={Data.quote.body} />
          </ApproachQuotation>
        </SectionWrapper>
        <SectionWrapper style={{ padding: "0" }} >
          <TalkWithUs data={Data.talkwithus} />
        </SectionWrapper>
        <SectionWrapper className='section5'>
          <BlockContainer>
            <Block className='block1'>
              <div className='content'>
                <HTMLText classes={'title '+Styles.standardTitle} styles={{color: "#FFF", marginBottom: "5px"}} htmlString={Data.aboutUs.block1.title} />
                <HTMLText classes={Styles.standardBody} styles={{color: "#FFF", marginBottom: "5px"}} htmlString={Data.aboutUs.block1.body} />
              </div>
              <div className='button'>
                <ActionButton to='#'>{Data.aboutUs.block1.button.label}</ActionButton>
              </div>
            </Block>
            <Block className='block2'>
              <div className='content'>
                <HTMLText classes={'title '+Styles.standardTitle} styles={{color: "#000", marginBottom: "5px"}} htmlString={Data.aboutUs.block2.title} />
                <HTMLText classes={Styles.standardBody} styles={{color: "#000", marginBottom: "5px"}} htmlString={Data.aboutUs.block2.body} />
              </div>
              <div className='button'>
                <ActionButton to='#' transparentbg='true'>{Data.aboutUs.block2.button.label}</ActionButton>
              </div>
            </Block>
          </BlockContainer>
        </SectionWrapper>
        <SectionWrapper className='section section6' style={{borderBottom: "2px solid #FFFFFF"}}>
          <Openings data={Data.openings} />
        </SectionWrapper>
        <SectionWrapper className='section section7'>
          <Footer pageID={2} />
        </SectionWrapper>
      </SectionsContainer>
    </>
  )
}

export default Approach