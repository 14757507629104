import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Styles from '../../css/styles.module.css';

const ContactUsTitleSection = styled.section`
    // height: 69vh;
    // max-height: 1100px;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const ContactUsTitleWrapper = styled.div`
    // align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: center;
    margin: 0 auto;
    // margin-left: 4rem;
    position: relative;
    // overflow: hidden;
`;
const ContactUsQuoteHolder = styled.div`
    display: flex;
    justify-content: center;
    margin: 270px 0 210px;
    width: 100%;

    @media screen and (max-width: 1370px) {
        // margin: 190px auto 120px;
        // margin-left: 1.5rem;
        // max-width: 1200px;
    }
    @media screen and (max-width: 768px) {
        margin: 190px auto 120px;
    }
`
const ContactUsQuote = styled.div`
    background: linear-gradient(270deg, rgb(187, 216, 239) 0%, rgb(135, 147, 243) 50%, rgb(246, 199, 255) 100%);
    background-clip: border-box;
    color: #00FFC2;
    font-family: "sharp-grotesk-book", sans-serif;
    font-size: 65px;
    font-style: italic;
    // max-width: 1100px;
    text-align: center;
    text-transform: uppercase;
    width: max-content;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 768px) {
        font-size: 35px
    }
`
const ContactUsContent = styled.div`
    background-color: #1D1D1D;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #1D1D1D;
    // font-size: 40px;
    // font-style: italic;
    // font-weight: 100;
    margin: 0 auto;
    max-width: 1200px;
    padding: 64px 110px 86px;
    text-align: center;
    // text-transform: uppercase;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 40px 20px 60px;
    }
`
const Title = styled.div`
    color: #FFFFFF !important;
    margin-bottom: 86px;
`
const ContactUsBlocks = styled.div`
    column-gap: 80px;
    display: flex;
    justify-content: center;

    & > * {
        flex: 0 auto;
    }

    @media screen and (max-width: 768px) {
        align-items: center;
        flex-direction: column;
        row-gap: 35px;
    }
`
const ContactUsBlock = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 315px;

    @media screen and (max-width: 470px) {
        min-width: 250px;
    }
`
const BlockBody = styled.div`
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 100;
    margin: 12px auto 0;
    max-width: 247px;
    text-align: left;

    @media screen and (max-width: 768px) {
        display: none;
    }
`
const BlockButton = styled(Link)`
    background: radial-gradient(ellipse at bottom left,rgb(105, 102, 255) 0%,rgb(151, 92, 229) 48%,rgb(249, 114, 116) 100%);
    // background-size: 645px 105px;
    border-radius: 35px;
    color: #ffffff;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    max-height: 70px;
    max-width: 315px;
    padding: 21px;
    position: relative;
    text-decoration: none;
    width: 100%;
    z-index: 5;

    &:after {
        background: radial-gradient(ellipse at bottom left,rgb(249, 114, 116) 0%,rgb(151, 92, 229) 48%,rgb(105, 102, 255) 100%);
        content: '';    
        display: block;
        height: 100%;
        position: absolute;
        top: 0; 
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -5;
        transition: opacity 1s;
        border-radius: 35px;
    }

    &:hover {
        // opacity: 0.76;

        &:after {
            opacity: 1;
        }
    }

    @media screen and (max-width: 768px) {
        margin: 0 auto;
    }
    @media screen and (max-width: 470px) {
        font-size: 17px;
    }

    &.queryButton {
        background: #FFFFFF;
        border: 3px solid #6966FF;
        color: #000000;

        &:hover::after {
            opacity: 0;
        }
    }
`

const ContactUsTitle = ({ data }) => {
    return (
        <ContactUsTitleSection>
            <ContactUsTitleWrapper>
                <ContactUsQuoteHolder>
                    <ContactUsQuote>{data.text}</ContactUsQuote>
                </ContactUsQuoteHolder>
                <ContactUsContent>
                    <Title className={'title ' + Styles.standardTitle}>{data.title}</Title>
                    <ContactUsBlocks>
                        <ContactUsBlock>
                            <BlockButton to="#" onClick={(e) => {
                                window.location.href = 'mailto:hello@arize.co.in';
                                e.preventDefault();
                            }}>{data.buttonLabel1}</BlockButton>
                            <BlockBody>{data.body1}</BlockBody>
                        </ContactUsBlock>
                        <ContactUsBlock>
                            <BlockButton className="queryButton" to="#">{data.buttonLabel2}</BlockButton>
                            <BlockBody style={{ maxWidth: '230px' }}>{data.body2}</BlockBody>
                        </ContactUsBlock>
                    </ContactUsBlocks>
                </ContactUsContent>
            </ContactUsTitleWrapper>
        </ContactUsTitleSection>
    )
}

export default ContactUsTitle