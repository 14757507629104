import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const TalkWithUsSection = styled.section`
    color: #ffffff;
    float: left;
    padding: 0 0 140px;
    position: relative;
    text-align: center;
    width: 100%;
`;

const TalkWithUsWrapper = styled.div`
    row-gap: 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 140px auto 0;
    max-width: 980px;
    // overflow: hidden;
    position: relative;
    width: 100%;

    & > * {
        flex: 1 100%;
    }

    @media screen and (max-width: 650px) {
        justify-content: left;
        row-gap: 1rem;
    }
`;

const TalkWithUsTitle = styled.div`
    color: #000000;
    font-size: 60px;
    font-weight: 100;
    max-width: calc(100% - 10px);

    @media screen and (max-width: 650px) {
        font-size: 50px;
        max-width: calc(100% - 150px);
        text-align: left;
    }
    @media screen and (max-width: 470px) {
        font-size: 35px;
        max-width: calc(100% - 110px);
    }
    @media screen and (max-width: 355px) {
        font-size: 30px;
        max-width: calc(100% - 110px);
    }
`;

const BackgroundImage = styled.img`
    max-width: 196px;
    z-index: 10;

    @media screen and (max-width: 650px) {
        margin-right: -1px;
        position: absolute;
        right: 0;
        z-index: -1;
    }
    @media screen and (max-width: 470px) {
        // margin-right: -50px;
        max-width: 150px;
    }
`;

const LetsTalkLink = styled(Link)`
    background: radial-gradient(ellipse at bottom left,rgb(105, 102, 255) 0%,rgb(151, 92, 229) 48%,rgb(249, 114, 116) 100%);
    // background-size: 645px 105px;
    border-radius: 35px;
    color: #ffffff;
    display: inline-block;
    font-size: 22px;
    font-weight: 600;
    margin-left: -10px;
    max-height: 70px;
    max-width: 425px;
    padding: 21px;
    position: relative;
    text-decoration: none;
    width: 100%;
    z-index: 5;

    &:after {
        background: radial-gradient(ellipse at bottom left,rgb(249, 114, 116) 0%,rgb(151, 92, 229) 48%,rgb(105, 102, 255) 100%);
        content: '';    
        display: block;
        height: 100%;
        position: absolute;
        top: 0; 
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -5;
        transition: opacity 1s;
        border-radius: 35px;
    }

    &:hover {
        // opacity: 0.76;

        &:after {
            opacity: 1;
        }
    }

    @media screen and (max-width: 650px) {
        // bottom: 1rem;
        margin-left: 0;
        max-width: calc(100% - 10px);
        // position: absolute;
    }
    @media screen and (max-width: 470px) {
        font-size: 17px;
    }
`;

const Shadow = styled.div`
    background: rgba(241,241,241, 0.4);
    border-radius: 50%;
    height: 175px;
    margin-top: -3rem;
    padding: 0 5rem;
    position: relative;
    width: 100%;
    -webkit-filter: blur(10px);
    // -webkit-transform: scale(1, 0.2);

    @media screen and (max-width: 650px) {
        // height: 100px;
        margin-top: -1.5rem;
        padding: 0;
        z-index: -2;
    }

    &.inner-shadow {
        background: rgba(201,201,201,0.35);
        height: 100px;
        margin: 0 auto;
        max-width: 850px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-filter: blur(5px);

        @media screen and (max-width: 650px) {
            margin-left: 40%;
            max-width: 60%;
            transform: translate(5%, -75%);
            z-index: -2;
        }
        @media screen and (max-width: 355px) {
            transform: translate(-15%, -70%);
        }
    }
`;

const TalkWithUs = ({ data }) => {
    const shell = {
        paddingLeft: '10px'
    }
    return (
        <div style={shell}>
            <TalkWithUsSection >
                <TalkWithUsWrapper >
                    <TalkWithUsTitle>{data.title}</TalkWithUsTitle>
                    <BackgroundImage src={data.asset} />
                    <LetsTalkLink to='#' key='1' onClick={(e) => {
                        window.location.href = data.btnLink;
                        e.preventDefault();
                    }}>{data.btnLabel}</LetsTalkLink>
                </TalkWithUsWrapper>
                <Shadow><Shadow className={'inner-shadow'} /></Shadow>
            </TalkWithUsSection>
        </div>
    )
}

export default TalkWithUs;