import BannerImage from "../images/alvin-image.png";
import ExpertiesIcon1 from "../images/learning-content.svg";
import ExpertiesIcon2 from "../images/learning-platforms.svg";
import ExpertiesIcon3 from "../images/digital-products.svg";
import ExpertiesIcon4 from "../images/user-experience.svg";
import ExpertiesIcon5 from "../images/design.svg";
import ExpertiesIcon6 from "../images/consultancy.svg";
import Industries1 from "../images/industries-1.png";
import Industries2 from "../images/industries-2.png";
import Industries3 from "../images/industries-3.png";
import Industries4 from "../images/industries-4.png";
import Industries5 from "../images/industries-5.png";
import Industries6 from "../images/industries-6.png";
import TalkWithUsImage from "../images/specialist.png";
import AccreLogo1 from "../images/accre-logo1.png";
import AccreLogo2 from "../images/accre-logo2.png";
import AccreLogo3 from "../images/accre-logo3.png";
import AccreLogo4 from "../images/accre-logo4.png";
import AccreLogo5 from "../images/accre-logo5.png";
import AccreLogo6 from "../images/accre-logo6.png";
import AccreLogo7 from "../images/accre-logo7.png";
import OpeningsImage from "../images/openings.png";

export const Data = {
    'home': {
        text: "In the twenty first century, the only literates will be the ones who can learn, unlearn and relearn.",
        signature: "~Alvin Tofley",
        companySlogan: "Arize Digital design compelling products. Focused on Digital Learning, Content Development and EdTech Platforms. We build solutions that help you excel.",
        path: "",
        asset: BannerImage
    },
    'experties': {
        title: "Services & Expertise",
        body: "We thrive at the intersection of design, technology and the user experience and help brands navigate showing up at their best. Our range of services include design, branding, user experience, digital learning, EdTech and product development.",
        items: [
            {
                title: "Learning Content",
                asset: ExpertiesIcon1
            },
            {
                title: "Learning Platforms",
                asset: ExpertiesIcon2
            },
            {
                title: "Digital Products",
                asset: ExpertiesIcon3
            },
            {
                title: "User Experience",
                asset: ExpertiesIcon4
            },
            {
                title: "Design",
                asset: ExpertiesIcon5
            },
            {
                title: "Consultancy",
                asset: ExpertiesIcon6
            }
        ]
    },
    'industries': {
        title: "Industries & Sectors",
        body: "Arize has been serving private enterprises big and small, public and government bodies as well as non-profit organisations.",
        items: [
            {
                title: "Retail & FMCG",
                asset: Industries1
            },
            {
                title: "Banking & Finance",
                asset: Industries2
            },
            {
                title: "Government & Public Services",
                asset: Industries3
            },
            {
                title: "Technology & Digital",
                asset: Industries4
            },
            {
                title: "Training & Education",
                asset: Industries5
            },
            {
                title: "Fashion & Lifestyle",
                asset: Industries6
            }
        ]
    },
    'talkwithus': {
        title: "How can we help you?",
        btnLabel: "Let's talk about your project",
        btnLink: "mailto:hello@arize.co.in",
        asset: TalkWithUsImage
    },
    'accreditations': {
        title: "Accreditations & Recognition",
        items: [
            {
                asset: AccreLogo1
            },
            {
                asset: AccreLogo2
            },
            {
                asset: AccreLogo3
            },
            {
                asset: AccreLogo4
            },
            {
                asset: AccreLogo5
            },
            {
                asset: AccreLogo6
            },
            {
                asset: AccreLogo7
            }
        ]
    },
    'openings': {
        title: "Got talent?",
        body: "We are always looking for like-minded and talented people to join our team and grow together.",
        link: "See open positions",
        asset: OpeningsImage
    }
};