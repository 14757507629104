import React from 'react';
import styled from 'styled-components/macro';
import ContactUsTitle from './ContactUsTitle';

const HeroSection = styled.section`
    height: 100%;
    left: 0;
    // max-height: 1050px;
    position: relative;
    top: 0;
    overflow: hidden;
    width: 100%;
`;

const HeroWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const HeroImage = styled.img`
    height: 100%;    
    object-fit: cover;
    // object-position: 75% 0%;
    position: absolute;
    width: 100%;
`;

const ContactUsHero = ({ data }) => {
    return (
        <HeroSection>
            <HeroWrapper>
                <HeroImage src={data.asset} />
                <ContactUsTitle data={data} />
            </HeroWrapper>
        </HeroSection>
    )
}

export default ContactUsHero;
