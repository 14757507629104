import BannerImage from "../images/contact-us-banner.png";
import IndiaSeperator from "../images/india-seperator.png";
import LondonSeperator from "../images/london-seperator.png";
import CanadaSeperator from "../images/canada-seperator.png";
import SpecialistImg from "../images/specialist-yoga-pose.png";
import OpeningsImage from "../images/openings.png";

export const Data = {
    'home': {
        text: "Let’s begin the conversation",
        title: "How can we help you?",
        buttonLabel1: "Start a project with us",
        body1: "Let us know about your requirement and we will get back to you within 24 hours.",
        buttonLabel2: "General Queries",
        body2: "For all other queries feel free to reach out to us.",
        asset: BannerImage
    },
    'locations': {
        title: "Reach out to us",
        items: [
            {
                location: "Jaipur, India",
                address: "Regus, 6th Floor, Jaipur Centre, Tonk Road, Jaipur, Rajasthan, India 302018",
                phone: "+91 9829 389 878",
                timeunit: "GMT+5:30",
                seperator: IndiaSeperator,
                timezone: 'india'
            },
            {
                location: "Pune, India",
                address: "403, Platinum Square, Vimaan Nagar Pune, Maharashtra, India 411014",
                phone: "+91 9829 389 878",
                timeunit: "GMT +5:30",
                seperator: IndiaSeperator,
                timezone: 'india'
            },
            {
                location: "London, England",
                address: "71-75 Shelton Street, London, England WC2H 9JQ",
                phone: "+44 (0) 203 861 7905",
                timeunit: "GMT",
                seperator: LondonSeperator,
                timezone: 'london'
            },
            {
                location: "Toronto, Canada",
                address: "3080 Yonge Street, Suite 6060 Toronto, Ontario, Canada M4N 3N1",
                phone: "+1 718 618 4863",
                timeunit: "GMT -4",
                seperator: CanadaSeperator,
                timezone: 'canada'
            }
        ]
    },
    "emailus": {
        title: "Or, simply email us at",
        body: "hello@arize.co.in",
        asset: SpecialistImg
    },
    'openings': {
        title: "Got talent?",
        body: "We are always looking for like-minded and talented people to join our team and grow together.",
        link: "See open positions",
        asset: OpeningsImage
    }
};