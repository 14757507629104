import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/dist/custom-animations/open-animation.css';
// import AwesomeSliderStyles from 'react-awesome-slider/dist/custom-animations/fall-animation.css';
// import AwesomeSliderStyles from 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
// import AwesomeSliderStyles from 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import '../css/slider.css';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const AutoplaySlider = withAutoplay(AwesomeSlider);
const AnimationStyle = 'openAnimation';

const Slider = ({slides}) => {
    return(
        <AutoplaySlider
            animation={AnimationStyle}
            organicArrows={false}
            buttons={false}
            buttonContentRight={<AiOutlineRight/>}
            buttonContentLeft={<AiOutlineLeft />}
            bullets={false}
            play={true}
            cancelOnInteraction={false}
            interval={6000}
            cssModule={AwesomeSliderStyles}
        >
            {slides.map((slide, index) => {
                return (
                    <div className='sliderContent' data-src={slide.asset} key={index}>
                        <div className={'sliderTitle'}>{slide.title}</div>
                        {/* <div className={'sliderBody'}>{slide.body}</div> */}
                    </div>
                )
            })}
        </AutoplaySlider>
    )
};

export default Slider