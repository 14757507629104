import BannerImage from "../images/services-banner.png";
import ExpertiesIcon1 from "../images/learning-content.svg";
import ExpertiesIcon2 from "../images/learning-platforms.svg";
import ExpertiesIcon3 from "../images/digital-products.svg";
import ExpertiesIcon4 from "../images/user-experience.svg";
import ExpertiesIcon5 from "../images/design.svg";
import ExpertiesIcon6 from "../images/consultancy.svg";
import NeedExpertsImage from "../images/need-experts.png";
import OpeningsImage from "../images/openings.png";

export const Data = {
    'home': {
        text: "Service Offerings",
        title: "Services & Expertise",
        body: "We thrive at the intersection of design, technology and the user experience and help brands navigate showing up at their best. Our range of services include design, branding, user experience, digital learning, EdTech and product development.",
        path: "",
        asset: BannerImage
    },
    'experties': {
        items: [
            {
                title: "Learning Content",
                asset: ExpertiesIcon1
            },
            {
                title: "Learning Platforms",
                asset: ExpertiesIcon2
            },
            {
                title: "Digital Products",
                asset: ExpertiesIcon3
            },
            {
                title: "User Experience",
                asset: ExpertiesIcon4
            },
            {
                title: "Design",
                asset: ExpertiesIcon5
            },
            {
                title: "Consultancy",
                asset: ExpertiesIcon6
            }
        ]
    },
    'needexperts': {
        body: "Need new inspiration?\nHave a chat with our experts!",
        btnLabel: "hello@arize.co.in",
        btnLink: "mailto:hello@arize.co.in",
        asset: NeedExpertsImage,
        actionBtnLabel1: "Approach",
        actionBtnLabel2: "Request Demo",
    },
    'openings': {
        title: "Got talent?",
        body: "We are always looking for like-minded and talented people to join our team and grow together.",
        link: "See open positions",
        asset: OpeningsImage
    }
};