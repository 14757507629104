import React from 'react';
import styled from 'styled-components/macro';
import Styles from '../../css/styles.module.css';

const AboutUsTitleSection = styled.section`
    // height: 69vh;
    // max-height: 1100px;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const AboutUsTitleWrapper = styled.div`
    // align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: center;
    margin: 0 auto;
    // margin-left: 4rem;
    position: relative;
    // overflow: hidden;
`;
const AboutUsQuoteHolder = styled.div`
    display: flex;
    justify-content: center;
    margin: 240px 0 180px;
    width: 100%;

    @media screen and (max-width: 768px) {
        margin: 190px auto 120px;
    }
`
const AboutUsQuote = styled.div`
    background: linear-gradient(270deg, rgb(187, 216, 239) 0%, rgb(135, 147, 243) 50%, rgb(246, 199, 255) 100%);
    background-clip: border-box;
    color: #00FFC2;
    font-family: "sharp-grotesk-book", sans-serif;
    font-size: 65px;
    font-style: italic;
    // max-width: 1100px;
    text-align: center;
    text-transform: uppercase;
    width: max-content;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 768px) {
        font-size: 35px
    }
`
const AboutUsSlogan = styled.div`
    background-color: #6966FF;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #1D1D1D;
    font-size: 40px;
    font-style: italic;
    font-weight: 100;
    margin: 0 auto;
    max-width: 1200px;
    padding: 54px 110px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        // font-family: "sharp-grotesk-book", sans sarif;
        // font-size: 28px;
        padding: 20px 10px;
    }
`
const SloganTitle = styled.div`
    color: #FFFFFF !important;
`
const SloganBody = styled.div`
    color: #FFFFFF !important;
    font-size: 40px !important;
    // @media screen and (max-width: 768px) {
    //     display: none;
    // }
`

const AboutUsTitle = ({ data }) => {
    return (
        <AboutUsTitleSection>
            <AboutUsTitleWrapper>
                <AboutUsQuoteHolder>
                    <AboutUsQuote>{data.text}</AboutUsQuote>
                </AboutUsQuoteHolder>
                <AboutUsSlogan>
                    <SloganTitle className={'title ' + Styles.standardTitle}>{data.title}</SloganTitle>
                    <SloganBody className={Styles.standardBody}>{data.body}</SloganBody>
                </AboutUsSlogan>
            </AboutUsTitleWrapper>
        </AboutUsTitleSection>
    )
}

export default AboutUsTitle