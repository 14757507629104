import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import Styles from '../../css/styles.module.css';
import IntersectionVisible from 'react-intersection-visible';

const ApproachTitleSection = styled.section`
    // height: 69vh;
    // max-height: 1100px;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const ApproachTitleWrapper = styled.div`
    // align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: center;
    margin: 0 auto;
    // margin-left: 4rem;
    position: relative;
    // overflow: hidden;
`;

const yAnimation1 = keyframes`
    0% {top: -372px}
    100% {top: 0}
`
const yAnimation2 = keyframes`
    0% {top: -372px}
    100% {top: 50px}
`
const BreathInOut1 = keyframes`
    0%, 100% {top: 0}
    50% {top: -30px}
`
const BreathInOut2 = keyframes`
    0%, 100% {top: 50px}
    50% {top: 0px}
`
const ApproachQuoteHolder = styled.div`
    margin: 240px 0 180px;
    width: 100%;

    .slideIn {
        margin: 0 auto;
        position: relative;

        img {
            max-width: 256px;
            position: relative;
            top: 100vh;
        }
        &.animate-in {
            .hero1 {
                animation: 0.75s ease-out 1 ${yAnimation1} forwards, 2.5s ease-in-out 0.75s infinite ${BreathInOut1};
            }

            .hero2 {
                animation: 0.75s ease-out 0.25s 1 ${yAnimation2} forwards, 2.5s ease-in-out 1s infinite ${BreathInOut2};
            }
        }
    }

    @media screen and (max-width: 1370px) {
        // margin: 190px auto 120px;
        // margin-left: 1.5rem;
        // max-width: 1200px;
    }
    @media screen and (max-width: 768px) {
        margin: 190px auto 120px;
    }
`
const ApproachQuote = styled.div`
    color: #000000;
    font-family: "sharp-grotesk", sans-serif;
    font-size: 65px;
    font-style: italic;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @media screen and (max-width: 768px) {
        font-size: 35px
    }
`
const ApproachSlogan = styled.div`
    background-color: #1D1D1D;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #1D1D1D;
    font-size: 40px;
    font-style: italic;
    font-weight: 100;
    margin: 0 auto;
    max-width: 1200px;
    padding: 54px 110px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        // font-family: "sharp-grotesk-book", sans sarif;
        // font-size: 28px;
        padding: 20px 10px;
    }
`
const SloganTitle = styled.div`
    color: #FFFFFF !important;
`
const SloganBody = styled.div`
    color: #FFFFFF !important;
    font-size: 40px !important;
    // @media screen and (max-width: 768px) {
    //     display: none;
    // }
`
const FlyingHeros = styled.img`
    left: 50%;
    transform: translateX(-50%);

    &.hero2 {
        position: absolute !important;
        transform: translate(-23%, -13%);
    }
`

const ApproachTitle = ({data}) => {
  const onShow = ( entries ) => {
    if(!entries[0].target.classList.contains('animate-in')) {
      entries[0].target.classList.add('animate-in');
    }
  }
  return (
    <ApproachTitleSection>
        <ApproachTitleWrapper>
            <ApproachQuoteHolder>
                <IntersectionVisible options={{threshold: 1}} className='slideIn' onShow={ e => onShow( e ) }>
                    <FlyingHeros className="hero2" src={data.asset.hero2} />
                    <FlyingHeros className="hero1" src={data.asset.hero1} />
                    {/* <FlyingHeros src={data.asset.heros} /> */}
                </IntersectionVisible>
                <ApproachQuote>{data.text}</ApproachQuote>
            </ApproachQuoteHolder>
            <ApproachSlogan>
                <SloganTitle className={'title '+Styles.standardTitle}>{data.title}</SloganTitle>
                <SloganBody className={Styles.standardBody}>{data.body}</SloganBody>
            </ApproachSlogan>
        </ApproachTitleWrapper>
    </ApproachTitleSection>
  )
}

export default ApproachTitle