import React from 'react';
import styled from 'styled-components/macro';
import Styles from '../../css/styles.module.css';

const ServicesTitleSection = styled.section`
    // height: 69vh;
    // max-height: 1100px;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const ServicesTitleWrapper = styled.div`
    // align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: center;
    margin: 0 auto;
    // margin-left: 4rem;
    position: relative;
    // overflow: hidden;
`;
const ServicesQuoteHolder = styled.div`
    margin: 270px 0 210px;
    width: 100%;

    @media screen and (max-width: 1370px) {
        // margin: 190px auto 120px;
        // margin-left: 1.5rem;
        // max-width: 1200px;
    }
    @media screen and (max-width: 768px) {
        margin: 190px auto 120px;
    }
`
const ServicesQuote = styled.div`
    background: linear-gradient(90deg, rgb(246, 199, 255) 0%, rgb(135, 147, 243) 50%, rgb(187, 216, 239) 100%);
    background-clip: border-box;
    color: #00FFC2;
    font-family: "sharp-grotesk-book", sans-serif;
    font-size: 65px;
    font-style: italic;
    // max-width: 1100px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 768px) {
        font-size: 35px
    }
`
const ServicesSlogan = styled.div`
    background-color: #1D1D1D;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #1D1D1D;
    // font-size: 40px;
    // font-style: italic;
    // font-weight: 100;
    margin: 0 auto;
    max-width: 1200px;
    padding: 54px 110px;
    text-align: center;
    // text-transform: uppercase;

    @media screen and (max-width: 768px) {
        // font-family: "sharp-grotesk-book", sans sarif;
        // font-size: 28px;
        padding: 20px 10px;
    }
`
const SloganTitle = styled.div`
    color: #FFFFFF !important;
`
const SloganBody = styled.div`
    color: #FFFFFF !important;
    
    // @media screen and (max-width: 768px) {
    //     display: none;
    // }
`

const ServicesTitle = ({data}) => {
  return (
    <ServicesTitleSection>
        <ServicesTitleWrapper>
            <ServicesQuoteHolder>
                <ServicesQuote>{data.text}</ServicesQuote>
            </ServicesQuoteHolder>
            <ServicesSlogan>
                <SloganTitle className={'title '+Styles.standardTitle}>{data.title}</SloganTitle>
                <SloganBody className={Styles.standardBody}>{data.body}</SloganBody>
            </ServicesSlogan>
        </ServicesTitleWrapper>
    </ServicesTitleSection>
  )
}

export default ServicesTitle