import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { Data } from '../../data/ContactUsData';

import ContactUsHero from '../layouts/contactus/ContactUsHero';
import ContactUsLocations from '../layouts/contactus/ContactUsLocations';
import EmailUs from '../layouts/contactus/EmailUs';
import Openings from '../layouts/Openings';
import Footer from '../layouts/Footer';

import ScrollToTopOnMount from '../ScrollToTop';

const SectionsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled.section`
  // float: left;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 10px;
  position: relative;
  width: 100%
`;
const StyledSectionWrappr = styled(SectionWrapper)`
  &.locationSection {
    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 0;
    }
  }
`

// const LocationSection = css`
//   @media screen and (max-width: 768px) {
//     max-width: 100%;
//     padding: 0;
//   }
// `

const ContactUs = ({setPageID}) => {
  useEffect(() => {
    setPageID(5);

    // return () => {
    //     setPageID(5);
    // };
  }, []);
  return (
    <>
      <ScrollToTopOnMount />
      <ContactUsHero data={Data.home} />
      <SectionsContainer>
        <StyledSectionWrappr className={'locationSection'}>
          <ContactUsLocations data={Data.locations} />
        </StyledSectionWrappr>
        <SectionWrapper>
          <EmailUs data={Data.emailus} />
        </SectionWrapper>
        <SectionWrapper style={{maxWidth: "100%", padding: "0", borderBottom: "2px solid #FFFFFF"}}>
          <Openings data={Data.openings} />
        </SectionWrapper>
        <SectionWrapper style={{maxWidth: "100%", padding: "0"}}>
          <Footer pageID={5} />
        </SectionWrapper>
      </SectionsContainer>
    </>
  )
}

export default ContactUs