import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
    background: ${({primary}) => (primary ? "#282828" : "none")};
    border-radius: ${({primary}) => (primary ? "35px" : "none")};
    white-space: nowrap;
    border: none;
    height: 100%;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    // max-height: 35px;
    width: 151px;
    z-index: 5;

    &:after {
        background: linear-gradient(to right top, #6966ff, #7464fa, #7e62f6, #8660f1, #8e5eec, #a859e0, #bc54d4, #cc51c7, #e44faf, #f25698, #f96284, #f97274);
        content: '';    
        display: block;
        height: 100%;
        position: absolute;
        top: 0; 
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -5;
        transition: opacity 1s;
        border-radius: 35px;
    }

    &:hover {
        // transform: ${({dropdown}) => (dropdown ? "none" : "translateY(-2px)")};
        // background: radial-gradient(circle, rgba(105,102,255,1) 0%, rgba(151,92,229,1) 48%, rgba(249,114,116,1) 100%);
        // background: linear-gradient(to right top, #6966ff, #7464fa, #7e62f6, #8660f1, #8e5eec, #a859e0, #bc54d4, #cc51c7, #e44faf, #f25698, #f96284, #f97274);

        &:after {
            opacity: 1;
        }
    }
`;