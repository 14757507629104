import BannerImage from "../images/approach-banner.png";
import OpeningsImage from "../images/openings.png";
import FlyingHero1Image from "../images/flying-hero1.png";
import FlyingHero2Image from "../images/flying-hero2.png";
import QuoteBanner from "../images/approach-quote-banner.png";
import TalkWithUsImage from "../images/specialist.png";

export const Data = {
    'home': {
        text: "We work with you—not for you",
        body: "We co-design with our clients to create solutions that people love to use.",
        path: "",
        asset: {
            banner: BannerImage,
            hero1: FlyingHero1Image,
            hero2: FlyingHero2Image
        }
    },
    'ourApproach': {
        title: "Our Approach",
        body: "We thrive on our love for aesthetics, craftsmanship, and customer experience. At Arize, design thinking and creativity are woven into every step of our process.<br><br>While each client's journey is unique, certain elements of our approach remain consistent. The cornerstone of our methodology is understanding who we are designing for and what they truly need. From there, we engage in a dynamic process of rapid iterations—Observing, Reflecting, and Making (<a href='https://www.ibm.com/design/approach/design-thinking/' target='_blank'>as defined by IBM</a>). This cycle continues until we bring your vision to life."
    },
    'ourDrive': {
        title: "The loop that drive us",
        body: "Understand the present and shape the future through an ongoing cycle of observing, reflecting, and making."
    },
    'quote': {
        title: "“Great things are done by a series of small things brought together.”",
        body: "-Vincent Van Gough",
        asset: QuoteBanner
    },
    'talkwithus': {
        title: "How can we help you?",
        btnLabel: "Let's talk about your project",
        btnLink: "mailto:hello@arize.co.in",
        asset: TalkWithUsImage
    },
    'aboutUs': {
        block1: {
            title: "Case Studies",
            body: "See some of our selected work.",
            button: {
                label: "Case Studies",
                theme: "black"
            }
        },
        block2: {
            title: "About Us",
            body: "Know more about Arize Digital.",
            button: {
                label: "Learn More",
                theme: "black"
            }
        }
    },
    'openings': {
        title: "Got talent?",
        body: "We are always looking for like-minded and talented people to join our team and grow together.",
        link: "See open positions",
        asset: OpeningsImage
    }
};