import React from 'react';
import styled from 'styled-components/macro';

const HomeTitleSection = styled.section`
    // height: 69vh;
    // max-height: 1100px;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const HomeTitleWrapper = styled.div`
    // align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: center;
    margin: 0 auto;
    // margin-left: 4rem;
    position: relative;
    // overflow: hidden;
`;
const HomeQuoteHolder = styled.div`
    margin: 190px 0 120px 5rem;
    width: 100%;

    @media screen and (max-width: 1370px) {
        margin: 120px auto;
        margin-left: 1.5rem;
        max-width: 1200px;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 60px;
    }
`;
const HomeQuote = styled.div`
    color: #00FFC2;
    font-family: "sharp-grotesk-book25", sans-serif;
    font-size: 48px;
    max-width: 1100px;
    width: calc(65% - 4rem);

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
`;
const HomeSignature = styled.div`
    color: #00FFC2;
    font-size: 38px;
    font-style: italic;
    font-weight: 300;
    text-align: right;
    margin-top: 2rem;
    max-width: 1100px;
    width: calc(65% - 4rem);

    @media screen and (max-width: 768px) {
        font-size: 20px;
        margin-top: 1rem;
    }
`;
const HomeSlogan = styled.div`
    background-color: #F5F3F6;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #1D1D1D;
    font-size: 40px;
    font-style: italic;
    font-weight: 100;
    margin: 0 auto;
    max-width: 1200px;
    padding: 54px 110px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        font-family: "sharp-grotesk-book", sans sarif;
        font-size: 28px;
        padding: 20px 30px;
    }
`;

const HomeTitle = ({data}) => {
  return (
    <HomeTitleSection>
        <HomeTitleWrapper>
            <HomeQuoteHolder>
                <HomeQuote>{data.text}</HomeQuote>
                {data.signature ? <HomeSignature>{data.signature}</HomeSignature> : null}
            </HomeQuoteHolder>
            {data.companySlogan ? <HomeSlogan>{data.companySlogan}</HomeSlogan> : null}
        </HomeTitleWrapper>
    </HomeTitleSection>
  )
}

export default HomeTitle;