import React from 'react';

const HTMLText = ({classes, styles, htmlString}) => {
    return (
        <div
            className={classes}
            style={styles}
            dangerouslySetInnerHTML={{ __html: htmlString }} />
    )
};
export default HTMLText;